<template>
  <div class="container">
    <div class="card">
      <div class="content columns">
        <div class="column is-3">Source</div>
        <div class="column is-3">Category</div>
        <div class="column is-6">Link</div>
      </div>
    </div>
    <div
      class="card"
      v-for="(item, index) in sourceLinkArray"
      :key="index">
      <div class="content columns">
        <div class="column is-3" v-if="item.source.source_name">{{item.source.source_name}}</div>
        <div class="column is-3" v-else>
        <div class="form-group">
                <b-select placeholder="Select Source" expanded v-model="item.source">
                  <option v-for="option in dataSourceList.filter(source => source.source_id !== gartnerId)" :value="option" :key="option.source_id">
                    {{ option.source_name }}
                  </option>
                </b-select>
          <span v-if="!(item.source && item.source.source_id)" class="required-error">Please select source.</span>
          </div>
        </div>
        <div class="column is-3">{{item.category.category_name ? item.category.category_name : 'N/A'}}</div>
        <div class="column is-6 pr-4">
          <div class="form-group">
            <b-input
              class="input-field"
              :disabled="!(item.source && item.source.source_id)"
              placeholder="Link"
              @input="handleLinkInput(index)"
              v-model="item.link"></b-input>
          </div>
        </div>
      <b-icon
        v-if="item.isRemovable"
        icon="close"
        size="is-small"
        @click.native="handleRemove(item)"
        class="close-icon-btn"></b-icon>
      </div>
      <!-- <b-button type="is-danger" v-if="item.isRemovable" icon-right="close" size="is-small" /> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
/** This component contains the code required to render the source links form. It is currently used on the *Update Product* page */
export default {
  props: {
    /** This prop contains the list of sources for whom an input is required */
    sourceLinkArray: Array
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('dataSource', ['dataSourceList']),
    ...mapState('common', ['gartnerId'])
  },
  // watch: {
  // },
  // mounted () {
  // },
  methods: {
    /** This method emits a 'linkInput' event. */
    handleLinkInput (index) {
      this.$emit('linkInput', index, this.sourceLinkArray)
    },
    handleRemove (row) {
      this.$emit('removeRow', row.tempId, this.sourceLinkArray)
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    .card {
      color: var(--secondary-text-color);
      box-shadow: none;
      &:first-child {
        border: none;
        background: transparent;
      }
      &:not(:first-child) {
        background: var(--background-color); // transparent;
      }
      margin: 10px;
      // border: var(--tertiary-border-color);
      padding: 10px;
      padding-top: 0px;
      padding-bottom: 6px;
      .card-footer {
        border-top: var(--tertiary-border-color);
      }
      .columns {
        align-items: center;
      }
      .close-icon-btn {
        position: absolute;
        right: 0px;
        align-items: center;
        margin: -6px;
        cursor: pointer;
        &:hover {
          color: red;
        }
      }
    }
    .row-adder {
      color: #2780eb;
      padding-left: 10px;
    }
  }
</style>
