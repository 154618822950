<template>
  <div class="new-clients-container">
    <admin-header-vue alias="" pageName="Update Product" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column">
            <div class="display-flex">
              <upload-vue
                label="Light Logo"
                :submitted="submitted"
                :image="productDetails.properties.logo_light"
                @onChangeImage="onChangeLightLogo" />
              <upload-vue
                label="Dark Logo"
                :submitted="submitted"
                :image="productDetails.properties.logo_dark"
                @onChangeImage="onChangeDarkLogo" />
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="true"
              :value="productDetails.productName"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeName"
              label="Product Name" />
          </div>
          <div class="column is-4  form-group relative">
            <p>Company</p>
            <v-select
              label="companyName"
              placeholder="Type to search.."
              :options="localCompanyList"
              :loading="loadingSearchedCompanies"
              @search="handleCompanySearch"
              :clearSearchOnSelect="true"
              :value="selectedCompany"
              @input="onChangeProductCompany"
              :filterable="false">
              <template slot="no-options">
                Type to search..
            </template></v-select>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <input-vue
              :required="false"
              :value="productDetails.description"
              type="textarea"
              :submitted="true"
              :max="500"
              @onChangeName="onChangeDescription"
              label="Description" />
          </div>
          <div class="column alias-area is-6">
            <input-vue
              :required="false"
              :value="aliasInput"
              type="textarea"
              :submitted="submitted"
              @onChangeName="onChangeAlias"
              label="Alias (one per line)" />
          </div>
        </div>
        <div class="columns">
          <source-link-cards
            @linkInput="(index, data) => handleLinkInput(index, data)"
            @removeRow="(tempId, data) => handleLinkRowRemoval(tempId, data)"
            :sourceLinkArray="sourceLinkArrayDetails" />
        </div>
        <div v-if="isRedditSourcePresent" class="columns">
          <b-button @click.prevent="addSourceLinkRow" class="add-new-topic"><p>+ Add more source Link</p></b-button>
        </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Update</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <modal-vue @onChange="toggleConfirmationModal" :modal="askConfirmation">
      <div class="user-delete-modal">
        <div class="user-delete-inside">
          <p class="modal-header">Confirm update</p>
          <div class="modal-data">
            <p v-if="showWarning">
            Changes in the source links will impact few subsriptions.
          </p>
          <p>
            Are you sure you want to make changes to the source links ?
          </p>
          <div class="actions">
            <b-button @click="toggleConfirmationModal(false)">Cancel</b-button>
            <b-button @click="submit">Update</b-button>
          </div>
          </div>
        </div>
      </div>
    </modal-vue>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'
import UploadVue from '../../components/Upload.vue'
import Snackbar from '../../../../components/Snackbar'
import SourceLinkCards from './SourceLinkCards'
import { debounce } from '../../../../util/util'
import ModalVue from '../../../Admin/components/Modal.vue'

/**
 * Form to update a Product.
 * @displayName UpdateProduct
 */
export default {
  name: 'Clients',
  components: {
    AdminHeaderVue,
    InputVue,
    UploadVue,
    SourceLinkCards,
    ModalVue
  },
  data () {
    return {
      /**
       * Object containing form data which will be sent to the API
       * @type {Object}
       */
      productDetails: {
        productName: null,
        properties: {
          logo_light: null,
          logo_dark: null
        },
        description: null,
        lightLogoName: null,
        darkLogoName: null,
        productId: null,
        companyId: null,
        alias: null
      },
      localCompanyList: [],
      loadingSearchedCompanies: false,
      /**
       * Field to track the form submission status, to be used to show/hide the validation messages
       * @type {Boolean}
       */
      submitted: false,
      aliasInput: null,
      isAliasValid: true,
      isLightLogoInvalid: false,
      isDarkLogoInvalid: false,
      isProductNameInvalid: true,
      isProductDescriptionInvalid: false,
      sourceLinkArrayDetails: [],
      productFormLinkArray: [],
      areLinksValid: true,
      areSourcesValid: true,
      selectedCompany: {
        companyId: null,
        companyName: null
      },
      askConfirmation: false,
      showWarning: false
    }
  },
  computed: {
    ...mapState('categories', ['categoriesOfProduct']),
    ...mapState('products', ['productDetail', 'productLinkMeta']),
    ...mapState('dataSource', ['dataSourceList']),
    ...mapState('common', ['gartnerId']),
    ...mapState('user', ['userDetails']),
    ...mapState('company', ['companyList']),
    isRedditSourcePresent () {
      let isRedditPresent = false
      if (this.dataSourceList && this.dataSourceList.length) {
        const redditSource = this.dataSourceList.find(sl => sl.source_name.toLowerCase() === 'reddit')
        if (redditSource) {
          isRedditPresent = true
        }
      }
      return isRedditPresent
    }
  },
  watch: {
    productDetail () {
      const { product_name: productName, properties, description, company_id: companyId, alias, company_name: companyName } = this.productDetail
      this.productDetails.productName = productName
      this.productDetails.properties.logo_light = properties.logo_light
      this.productDetails.properties.logo_dark = properties.logo_dark
      this.productDetails.lightLogoName = null
      this.productDetails.darkLogoName = null
      this.productDetails.description = description
      this.productDetails.companyId = companyId
      this.productDetails.alias = alias
      this.aliasInput = alias ? alias.join('\n') : null
      this.selectedCompany = { companyName: companyName, companyId: companyId }
    },
    productLinkMeta () {
      const gartnerId = this.gartnerId
      // create linkList from productLink Meta and add the missing sources
      const linkList = []
      this.productLinkMeta.forEach((meta) => {
        const linkRow = {
          source: this.dataSourceList.find(
            (s) => s.source_id === meta.source_id
          ),
          category: this.categoriesOfProduct.find(
            (c) => c.category_id === meta.category_id
          ) || { category_id: null, category_name: null },
          link: meta.link,
          plmId: meta.plm_id
        }
        linkList.push(linkRow)
      })

      this.dataSourceList.forEach((source) => {
        if (source.source_id === gartnerId && gartnerId > 0) {
          this.categoriesOfProduct.forEach((category) => {
            const obj = {
              source: { source_id: null, source_name: null },
              category: { category_id: null, category_name: null },
              link: null,
              plmId: null
            }
            obj.source = source
            obj.category = category
            const existingMetaData = linkList.find((m) => {
              return (
                m.source.source_id === source.source_id &&
                m.category.category_id == category.category_id
              )
            })
            if (!existingMetaData) {
              linkList.push(obj)
            }
          })
        } else {
          const obj = {
            source: { source_id: null, source_name: null },
            category: { category_id: null, category_name: null },
            link: null,
            plmId: null
          }
          obj.source = source
          const existingMetaData = linkList.find((m) => {
            return m.source.source_id === source.source_id
          })
          if (!existingMetaData) {
            linkList.push(obj)
          }
        }
      })

      this.sourceLinkArrayDetails = linkList.sort(function (a, b) {
        if (a.source.source_name < b.source.source_name) {
          return -1
        }
        if (a.source.source_name > b.source.source_name) {
          return 1
        }
        return 0
      })
      this.handleLinkInput()
    },
    dataSourceList () {
      if (this.dataSourceList.length && this.categoriesOfProduct) {
        this.prepareSourceLinkList()
      }
    },
    categoriesOfProduct () {
      if (this.dataSourceList.length && this.categoriesOfProduct) {
        this.prepareSourceLinkList()
      }
    }
  },
  mounted () {
    this.getDataSourceList(this.userDetails.modules.find(m => m.module_name === 'voc').module_id)
    this.getCategoriesOfProduct(this.$route.params.id)
    this.productDetails.productId = Number(this.$route.params.id)
  },
  methods: {
    ...mapActions('products', ['getSingleProduct', 'updateProduct', 'verifyLinkChangesImpact']),
    ...mapActions('categories', ['getCategoriesOfProduct']),
    ...mapActions('company', ['getCompanyList']),
    ...mapActions('dataSource', ['getDataSourceList']),

    /** This method toggles the visibility of the confirmation modal
     * @public
     */
    toggleConfirmationModal (value) {
      this.askConfirmation = value
    },

    handleCompanySearch (data) {
      if (data) {
        this.loadingSearchedCompanies = true
      }
      this.debounceCompanySearch(data)
    },

    debounceCompanySearch: debounce(async function (data) {
      if (data.length) {
        this.localCompanyList = await this.getCompanyList(data)
      }
      this.loadingSearchedCompanies = false
    }, 800),

    onChangeProductCompany (company) {
      if (company) {
        this.selectedCompany = company
        this.productDetails.companyId = company.companyId
      } else {
        this.selectedCompany = {
          companyId: null,
          companyName: null
        }
      }
    },

    /** This method adds a new row for reddit source links only. All other sources have only a single row
     * @public
     */
    addSourceLinkRow () {
      // const row = {
      //   source: this.dataSourceList.find(
      //     (s) => s.source_name === 'Reddit'
      //   ) || { source_id: null, source_name: null },
      //   category: { category_id: null, category_name: null },
      //   link: null,
      //   plmId: null
      // }
      const row = {
        source: { source_id: null, source_name: null },
        category: { category_id: null, category_name: null },
        link: null,
        plmId: null,
        isRemovable: true,
        tempId: this.sourceLinkArrayDetails.length
      }
      this.sourceLinkArrayDetails.push(row)
    },
    /**
     * This function gets called form the watchers, and prepares the combiation of the source and category to facilitate the input of the source links for the product.
     * @public
     */
    prepareSourceLinkList () {
      const listArr = []
      for (const source of this.dataSourceList) {
        if (source.source_id === this.gartnerId) {
          this.categoriesOfProduct.forEach((category) => {
            const obj = {
              source: { source_id: null, source_name: null },
              category: { category_id: null, category_name: null },
              link: null,
              plmId: null
            }
            obj.source = source
            obj.category = category
            listArr.push(obj)
          })
        } else {
          const obj = {
            source: { source_id: null, source_name: null },
            category: { category_id: null, category_name: null },
            link: null,
            plmId: null
          }
          obj.source = source
          listArr.push(obj)
        }
      }
      this.sourceLinkArrayDetails = listArr.sort(function (a, b) {
        if (a.source.source_name < b.source.source_name) {
          return -1
        }
        if (a.source.source_name > b.source.source_name) {
          return 1
        }
        return 0
      })
      this.getSingleProduct(this.$route.params.id)
    },
    /**
     * This function gets called on changing(input) of the light logo image for the product.
     * @param Object containing file and the name of the file
     * @param Boolean flag representing the validity
     * @public
     */
    onChangeLightLogo ({ file, name }, isInvalid) {
      this.productDetails.properties.logo_light = file
      this.productDetails.lightLogoName = name
      this.isLightLogoInvalid = isInvalid
    },
    /**
     * This function gets called on changing(input) of the dark logo image for the product.
     * @param Object containing file and the name of the file
     * @param Boolean flag representing the validity of the input
     * @public
     */
    onChangeDarkLogo ({ file, name }, isInvalid) {
      this.productDetails.properties.logo_dark = file
      this.productDetails.darkLogoName = name
      this.isDarkLogoInvalid = isInvalid
    },
    /**
     * This function gets called on input of the product name.
     * @param String product name
     * @param Boolean flag representing the validity of the input
     * @public
     */
    onChangeName (name, isInvalid) {
      this.productDetails.productName = name
      this.isProductNameInvalid = isInvalid
    },
    onChangeAlias (value, isInvalid) {
      value = value || ''
      this.productDetails.alias = value.split('\n').filter(a => a.trim() !== '')
    },
    onChangeDescription (description, isInvalid) {
      this.productDetails.description = description
      this.isProductDescriptionInvalid = isInvalid
    },
    onChangeCompany (company, isInvalid) {
      this.productDetails.companyId = company.id
    },
    /**
     * This function gets called from the handleSubmit function to check if any updates are available to be submitted.
     * @public
     */
    checkChanges () {
      if (this.productDetail.product_name !== this.productDetails.productName) {
        return true
      } else if (
        this.productDetail.properties.logo_dark !==
        this.productDetails.properties.logo_dark
      ) {
        return true
      } else if (
        this.productDetail.properties.logo_light !==
        this.productDetails.properties.logo_light
      ) {
        return true
      } else if (
        this.productDetail.description !== this.productDetails.description
      ) {
        return true
      } else if (
        this.productDetail.company_id !== this.productDetails.companyId
      ) {
        return true
      }
      return false
    },
    /**
     * This function gets called on click of submit button.
     * @public
     */
    async handleSubmit (e) {
      this.submitted = true
      const updatedPlm = this.getUpdatedPlmIds()
      if (!this.areLinksValid) {
        Snackbar({ message: 'Invalid source links Found.', type: 'is-danger' })
        this.areLinksValid = true
        return
      }
      if (!this.areSourcesValid) {
        Snackbar({ message: 'Found missing source selection', type: 'is-danger' })
        this.areSourcesValid = true
        return
      }
      const linkArray = this.productFormLinkArray
        .filter((e) => e.link)
        .map((e) => e.link)
      const formLinkSet = new Set(
        this.productFormLinkArray.filter((e) => e.link).map((e) => e.link)
      )
      if (formLinkSet.size < linkArray.length) {
        Snackbar({ message: 'Duplicate links Found.', type: 'is-danger' })
        return
      }
      let areLinksUpdated = false
      if (
        updatedPlm.length ||
        this.productLinkMeta.length < this.productFormLinkArray.length
      ) {
        areLinksUpdated = true
      }
      const areChangesFound = this.checkChanges()
      if (areChangesFound || areLinksUpdated) {
        if (
          !(
            this.isLightLogoInvalid ||
            this.isDarkLogoInvalid ||
            this.isProductNameInvalid ||
            this.isProductDescriptionInvalid ||
            (!this.isAliasValid)
          )
        ) {
          this.productDetails.productLinkMeta = this.productFormLinkArray.filter(
            (e) => updatedPlm.includes(e.plmId) || e.plmId === null
          )
          if (areLinksUpdated) {
            const res = await this.verifyLinkChangesImpact(this.productDetails.productLinkMeta)
            if (res === 200) {
              this.showWarning = true
            } else if (res === 201) {
              this.showWarning = false
            }
            this.askConfirmation = true
          } else {
            this.submit()
            this.askConfirmation = false
            this.productFormLinkArray = this.productFormLinkArray.filter(linkMeta => linkMeta.link)
          }
          // this.productDetails.productLinkMeta = this.productFormLinkArray.filter(
          //   (e) => updatedPlm.includes(e.plmId) || e.plmId === null
          // )
          // this.updateProduct(this.productDetails)
        } else {
          Snackbar({ message: 'Please check if all the inputs are valid.', type: 'is-danger' })
        }
      } else {
        Snackbar({ message: 'No Updates Found.', type: 'is-danger' })
      }
    },

    submit () {
      this.updateProduct(this.productDetails)
      this.askConfirmation = false
    },
    /**
     * This function gets called on click of cancel button. This function cancels the update and navigates to product list page.
     * @public
     */
    handleCancel () {
      this.$router.push('/admin/product')
    },
    /**
     * This function gets called from the handelSubmit function, It returns the array of plmIds for which the links have been updated.
     * It does not consider the newly added links as the plmIds will be null for new links.
     * @public
     */
    getUpdatedPlmIds () {
      const updatedPlm = []
      for (let i = 0; i < this.productFormLinkArray.length; i++) {
        const productSourceLinkObj = this.productFormLinkArray[i]
        if (productSourceLinkObj.link) {
          if (!this.isValidURL(productSourceLinkObj.link)) {
            this.areLinksValid = false
          }
        }
        if (!productSourceLinkObj.sourceId) {
          this.areSourcesValid = false
        }
        if (productSourceLinkObj.plmId) {
          const previousObj = this.productLinkMeta.find((d) => {
            return d.plm_id === productSourceLinkObj.plmId
          })
          if (previousObj && productSourceLinkObj.plmId && productSourceLinkObj.link !== previousObj.link) {
            updatedPlm.push(previousObj.plm_id)
          }
        }
      }
      return updatedPlm
    },
    /**
     * This function gets called on input of the link. It prepares the form input array to be sent to the API.
     * @public
     */
    handleLinkInput (index, data) {
      this.productFormLinkArray = this.sourceLinkArrayDetails
        .filter(
          (e) =>
            e.link != null ||
            this.productLinkMeta.map((e) => e.plm_id).includes(e.plmId)
        )
        .map((e) => {
          return {
            sourceId: e.source.source_id,
            categoryId: e.category.category_id,
            plmId: e.plmId,
            link: e.link,
            productId: Number(this.$route.params.id),
            isRemovable: e.isRemovable,
            tempId: e.tempId
          }
        })
      this.productFormLinkArray = this.productFormLinkArray.filter(
        (e) => !(e.plmId === null && e.link === '')
      )
    },
    handleLinkRowRemoval (tempId, data) {
      this.sourceLinkArrayDetails = data.filter(linkObj => linkObj.tempId !== tempId)
      this.productFormLinkArray = this.productFormLinkArray.filter(linkObj => linkObj.tempId !== tempId)
    },
    /**
     * This function gets called from handelSubmit function it is used to check if the input link is a valid URL
     * @public
     */
    isValidURL (string) {
      const res = string.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      )
      return res !== null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../Companies/vue-select-custom.scss";

.new-clients-container {
  margin-top: 20px;
  background: var(--primary);
  border-radius: 4px;
  .new-client {
    .columns {
      margin: 0px;
      .column {
        .display-flex {
          display: flex;
        }
        .display-flex div:nth-child(2) {
          padding-left: 20px;
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
        }
      }
      .add-new-topic {
        padding: 10px 0px 0px 10px;
        height: auto;
        background-color: transparent;
        border: none;
        p {
          font-size: 14px;
          color: #3c90f5;
          font-family: 'Quicksand';
        }
      }
    }
  }
}
::v-deep .form-group {
  p {
    display: flex;
    margin: 0px;
    color: #7f8fa4;
    font-size: 12px;
    font-family: Quicksand;
    padding: 0px 20px 0px 0px;
    display: flex;
    line-height: 20px;
  }
  .textarea:focus, .textarea:hover {
    border: var(--tertiary-border-color);
  }
}

::v-deep .data-source-header {
  width: fit-content !important;
}

::v-deep .alias-area {
  textarea {
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
  }
}

.user-delete-modal {
    display: flex;
    justify-content: center;
    .user-delete-inside {
      display: flex;
      color: var(--secondary-text-color);
      font-family: Quicksand;
      flex-direction: column;
      background: var(--dropdown-backgroud);
      border-radius: 4px;
      width: 400px;
      height: 200px;
      padding: 25px;
      .modal-data {
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    p {
      font-size: 14px;
    }
      }
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }

    }
    .actions {
      padding-top: 20px;
      text-align: end;
      .button {
        height: 32px;
        border: none;
        font-size: 14px;
        width: 75px;
      }
      .button:nth-child(1) {
        margin-left: 5px;
        background-color: transparent;
        color: var(--secondary-text-color);
        border: 1px solid var(--primary-border-color);
        &:hover {
          border: 2px solid var(--primary-border-color);
          transition: border 0.3s ease-out;
        }
        &:active {
          background-color: #7F8FA466;
          transition: background-color 0.3s ease-out;
        }
      }
      .button:nth-child(2) {
        margin-left: 5px;
        background-color: #FF0000;
        color: white;
        &:hover {
          background-color: #F64A4A;
          transition: background-color 0.3s ease-out;
        }
        &:active {
          background-color: #EE1111;
          transition: background-color 0.3s ease-out;
        }
      }
    }
  }
</style>
